<template>
  <div>
    <b-form-group
    :label="field.label + ':'">
      <b-form-input
        :id="'input-' + field.key"
        v-model="form[field.key]"
        type="text"
        :min="field.min"
        :placeholder="field.placeholder"
        required
      ></b-form-input>
    </b-form-group>

    <p v-if="form['date'] && form['hardware'] !== undefined && form['proposta'] !== undefined">
      {{ getSerial(form['sui'], form['date'], form['proposta'], form['quantity'], form['hardware']) }}
    </p>

  </div>
</template>

<script>
export default {
  props: {
    form: {
      type: Object
    },
    field: {
      type: Object
    }
  },
  methods: {
    getSerial (sui, date, proposta, hardware) {
      if (hardware < 10) {
        hardware = '0' + hardware
      }
      this.form.serial = sui + date.substring(2, 4) + date.substring(5, 7) + proposta + '01' + hardware
      return this.form.serial
    }
  }
}
</script>
