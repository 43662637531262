import { baseMutations, baseActions } from '../functions'
import { uuid } from '@/regexs'

export default {

  namespaced: true,
  state: {
    // Info
    items: [],
    name: 'Ativos',
    loading: true,
    noMenu: true,

    // Routes - Components actions
    actions: {
      // Main Action
      component: 'Cards.Actives',
      props: { title: 'Ativos', module: 'atives' },
      params: ['systemId' + uuid],
      children: [
        {
          key: 'modal',
          scope: 'Title',
          options: { label: 'Configurar Ativos', icon: 'add' },
          event: 'modal',
          component: 'Actives',
          params: route => ({ systemId: route.params.systemId, systemSUI: route.params.systemSUI })
        },
        {
          key: 'config',
          event: 'link',
          component: 'OutputsSettingsTable',
          props: route => ({ systemId: route.params.systemId, title: 'Cadastrar Configurações de Fábrica', module: 'atives' })
        },
        {
          key: 'add',
          event: 'link',
          component: 'Form',
          props: route => ({ systemId: route.params.systemId, systemSUI: route.params.systemSUI, title: 'Cadastrar lista de ativos', module: 'atives', formKey: 'save', requests: 'add' })
        },
        {
          key: 'modal2',
          scope: 'Buttons',
          options: { theme: 'success' }
        },
        {
          key: 'modal3',
          scope: 'Buttons',
          options: { theme: 'danger' }
        },
        {
          key: 'userId',
          props: route => ({ userId: route.params.userId, requests: this.userId !== undefined ? 'assetsUser' : null })
        }
      ]
    },

    // API Get index
    get: {
      urn: '/system/assets/index?system_id=:systemId',
      response: 'assets',
      lazy: true
    },

    // API routes
    requests: {
      assetsUserId: {
        title: 'Ativos',
        urn: '/system/assets/index?system_id=:systemId',
        response: 'assets',
        methods: 'get'
      },
      add: {
        title: 'Cadastrar novo ativo',
        success: 'Nova configuração cadastrada com successo',
        urn: 'system/assets/create',
        method: 'post'
      }
    },

    // Models
    models: {
    },

    // Forms
    forms: {
      save: [
        { key: 'sui', type: 'label', label: 'SUI', from: 'route', value: 'systemSUI', col: 3 },
        { key: 'system_id', type: 'hidden', from: 'route', value: 'systemId' },
        { key: 'hardware', type: 'text', label: 'Versão do Hardware', col: 3, max: 2 },
        { key: 'date', type: 'date', label: 'Escolha a data', col: 3 },
        { key: 'quantity', type: 'text', label: 'Quantidade de Equipamentos', col: 3, max: 2 },
        { key: 'proposta', type: 'view', view: 'Inputs.Draw', placeholder: 'Número da Proposta', label: 'Número da Proposta', col: 3, max: 6 },
        { key: 'serial', type: 'hidden', from: 'store', value: 'serial' }
      ]
    }
  },
  mutations: Object.assign({}, baseMutations, {
    // ...
  }),
  actions: Object.assign({}, baseActions, {
    // ...
  })
}
