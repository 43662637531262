<script>

import { get, toast } from '@/utils'
import { api } from '@/services'

export default {
  model: {
    prop: 'values',
    event: 'change'
  },
  props: {
    values: {
      type: Array,
      default: () => [{}]
    },
    fields: {
      // type: [Array, String],
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: false
    }
  },
  methods: {
    add () {
      this.$emit('change', [...this.values, {}])
    },
    del (index) {
      this.$emit('change', this.values.filter((e, i) => index !== i))
      if (this.title === 'Detalhes') {
        this.deleteDetail(this.values[index].title, this.values[index].description)
      }
    },
    deleteDetail (title, description) {
      api.post('/system/detail/delete/' + this.$route.params.id, { title: title, description: description }).then((response) => {
        console.log(response)

        if (response.status === 200) {
          toast(
            this,
            'success',
            'Detalhe excluído.',
            'Detalhe excluído com sucesso'
          )
        }
      })
    },
    input (e, key, index) {
      const clone = [...this.values]
      clone[index][key] = e

      this.$emit('change', clone)
    },
    get
  }
}
</script>
