import { baseMutations, baseActions } from '../functions'

export default {

  namespaced: true,
  state: {
    // Info
    classe: [],
    corte: [],
    remessa: [],
    arranjo: [],
    origem: [],
    localidade: [],
    bucket: [],
    items: [],
    name: 'Amostras',
    loading: true,
    noMenu: true,

    // Menu
    menu: {
      title: 'Amostras',
      icon: { key: 'home', custom: true }
    },

    // Routes - Components actions
    actions: {
      component: 'Table',
      props: { title: 'Análises', module: 'samples', modelKey: 'fields' },
      children: [
        {
          key: 'add',
          scope: 'Title',
          event: 'link',
          options: { label: 'Nova Análise', icon: 'add' },
          component: 'Form',
          props: { title: 'Nova Análise', module: 'samples', formKey: 'save', requests: 'add' }
        }
      ]
    },

    // API Get index
    get: {
      urn: '/object/index?user_id=',
      response: 'objects'
    },

    // API routes
    requests: {
      add: {
        title: 'Nova Análise',
        success: 'Análise iniciada',
        urn: '/analisar',
        method: 'postIot'
      }
    },

    // Table fields
    models: {
      fields: [
        { key: 'groups[0].key', label: 'Classe' },
        { key: 'groups[1].key', label: 'Corte' },
        { key: 'groups[2].key', label: 'Remessa' },
        { key: 'groups[3].key', label: 'Arranjo' },
        { key: 'groups[4].key', label: 'Origem' },
        { key: 'groups[5].key', label: 'Localidade' },
        { key: 'groups[6].key', label: 'Bucket' },
        { key: 'actions', label: '' }
      ]
    },

    // Forms
    forms: {
      save: [

        { key: 'classe', id: 'a01fe6e8-8970-40a7-9e57-0f58f59eaa40', label: 'Classe', type: 'multi-select', from: 'store', value: 'samples.classe', options: { value: 'classe', text: 'key' }, col: 6 },

        // select unico
        { key: 'corte', label: 'Corte', type: 'select-sample', from: 'store', value: 'samples.corte', options: { value: 'corte', text: 'key' }, col: 6 },

        { key: 'remessa', id: '7dd0bb28-d53c-406d-9fc0-a3c44905530d', label: 'Remessa', type: 'multi-select', from: 'store', value: 'samples.remessa', options: { value: 'remessa', text: 'key' }, col: 6 },
        { key: 'arranjo', id: 'c64104fa-45d9-4f08-a1d4-79a60f1ab0a6', label: 'Arranjo', type: 'multi-select', from: 'store', value: 'samples.arranjo', options: { value: 'arranjo', text: 'key' }, col: 6 },
        { key: 'origem', id: '0dd037c1-1254-40e5-91e1-5a86b734da10', label: 'Origem', type: 'multi-select', from: 'store', value: 'samples.origem', options: { value: 'origem', text: 'key' }, col: 6 },
        { key: 'localidade', id: '0f09c0f4-b7cc-4b5f-ad2d-cb101c08550c', label: 'Localidade', type: 'multi-select', from: 'store', value: 'samples.localidade', options: { value: 'localidade', text: 'key' }, col: 6 },

        // unico
        { key: 'bucket', label: 'Bucket', type: 'select-sample', from: 'store', value: 'samples.bucket', options: { value: 'id', text: 'key' }, col: 6 }

      ]
    }
  },

  mutations: Object.assign({}, baseMutations, {
    // ...
  }),
  actions: Object.assign({}, baseActions, {
    // ...
  })
}
