<template>
    <div>
        <Title v-if="!noTitle" :title="title"></Title>
        <Interface
            :lineLabel="name" :sortOptions="sortOptions"
            @sort="sort" @filter="filterSwitch"
        >
            <!-- Table -->
            <b-table ref="table" sticky-header="100vh" style="padding-bottom: 16px;"
                :items="fieldsOrder"
                :fields="fieldsWithEvents"
                :busy="isBusy"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :filter="filterData"
                :filter-function="filter"
                @filtered="onFiltered"
                @row-clicked="onRowClicked"
                select-mode="multi"
                show-empty
            >
                <!-- Temp edit input for variable table -->
                <template
                    v-if="state.hasEdit"
                    v-slot:cell()="{ value, item, field: { key } }"
                  >
                    <template v-if="edit != item.id">{{ value }}</template>
                    <template v-else>
                      <b-form-input v-if="key != 'total'" v-model="item[key]" />
                    </template>
                  </template>
                  <template v-if="state.hasEdit" v-slot:cell(actions)="{ item: { id }, item }">
                    <div class="btn-icons btn-actions">
                      <i
                        v-if="edit !== id"
                        @click="onEdit(id)"
                        class="material-icons btn-icon btn-icon-edit"
                        >edit
                      </i>
                      <i v-else @click="onSave(item, id)" class="material-icons btn-icon"
                        >check</i>
                      <i class="subtitle-table" v-if="edit !== id">Editar</i>
                    </div>
                  </template>
                <!-- Temp edit input for variable table - end -->

                <!-- Classificar -->
                <template v-slot:cell(actions)="{ item: { id }, item }">
                    <div class="btn-icons btn-actions">
                      <center>
                      <i
                        @click="onLink(item, id)"
                        class="material-icons btn-icon"
                        >list
                      </i>
                      <i class="subtitle-table">Classificar</i>
                      </center>
                    </div>
                  </template>

                <!-- Custom column for events-->
                <template v-if="actions.length > 0" #cell(events)="row">
                    <div class="btn-icons">
                        <i
                            v-for="action in actions"
                            :key="action.key"
                            @click="event(action, row.item)"
                            :class="'material-icons btn-icon' + action.options.theme ? (' bg-' + action.options.theme) : ''"
                        >{{ action.icon }}</i>
                    </div>
                </template>

                <!-- Custom column for select -->
                <template #cell(selected)="row">
                    <template>
                        <div class="checkbox">
                            <div :class="(isItemSelected(row.item) ? 'active ' : '') + 'checkbox-selected'"></div>
                        </div>
                    </template>
                </template>

                <!-- Loading Slot-->
                <template #table-busy>
                    <Loading></Loading>
                </template>

                <!-- Custom th for filter inputs -->
                <template #head()="key">

                    <!-- Checkbox -->
                    <template v-if="key.field.key == 'selected'">
                        <div @click="switchSelection" class="checkbox">
                            <div :class="(isAllRowsSelected ? 'active ' : '') + 'checkbox-selected'"></div>
                        </div>
                    </template>

                    <!-- Inputs -->
                    <template v-else>
                        <div>{{ key.label }}</div>
                        <input v-show="isFiltering"
                            type="text" class="form-control" :id="'filter-' + key.field.key"
                            v-model="filterData[key.field.key]"
                        />
                    </template>
                </template>

                <!-- Filter Search Empty -->
                <template #emptyfiltered="scope">
                    <p class="my-2 pl-2">{{ scope.emptyFilteredText }}</p>
                </template>

            </b-table>

            <!-- Pagination -->
            <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            size="md"
            align="right"
            class="my-0"
            ></b-pagination>

        </Interface>
    </div>
</template>

<style lang="stylus">

    @import '../../style/colors.styl';
    @import '../../style/mixins.styl';

    .btn-icons
      text-align -webkit-center
      display block

    .subtitle-table
      display block
      align-self center
      position relative
      font-size smaller
      font-style normal

    i.material-icons.btn-icon.btn-icon-edit
      opacity 0
      transition 0.3s
      background-color #ffc700

    .table
        color color-theme(true)
        border-collapse separate
        border-spacing 0 1em

    .table.b-table > thead > tr > .table-b-table-default, .table.b-table > tbody > tr > .table-b-table-default, .table.b-table > tfoot > tr > .table-b-table-default
        background-color secondary
        color unset

    .table td, .table th, .table thead th
        border none

    .table tr
        background-color content-base-theme()

    radius = 10px

    .table tr td:first-child
        border-top-left-radius radius
        border-bottom-left-radius radius

    .table tr td:last-child
        border-top-right-radius radius
        border-bottom-right-radius radius

    .table th div
        nowrap()

    .table.b-table > tbody > .table-active, .table.b-table > tbody > .table-active > th, .table.b-table > tbody > .table-active > td
        background-color content-base-theme()

    .table tr:focus
        outline none

    .table.b-table tr
      .btn-actions
          min-width 64px
      i
          opacity 0
          transition .3s

      &:hover
          i
              opacity 1

    .btn-icon-edit
      background-color: #ffc700

    .checkbox
        display block
        width 25px
        height 25px
        background-color #414D55
        padding 4px
        border-radius 4px
        cursor pointer

        .checkbox-selected
            min-height 100%
            border-radius: 4px
            transition 0.2s
            transform scale(0.2)

        .active
            background-color #2662F0
            width auto
            height auto
            transform scale(1)

</style>

<script>

/**
 * Component View - Table
 *
 * Table for module items
 *
 * Events: generated automatically as buttons in column 'events'
 *
 * Props:
 *   - model key for manual fields 'modelKey' not required
 *   - dynamic fields 'dynamic' default false
 *   + At least one of both, 'modelKey' and 'dynamic', MUST be pass
 *   + If both passed, 'modelKey' will be ignored
 */

import Table from '@/mixins/sets/TableBehavior'

import Title from '@/components/holders/Title'
import Interface from '@/components/holders/Interface'

import Loading from '@/components/static/Loading'

export default {
  mixins: [Table],
  components: { Title, Interface, Loading },
  props: {
    noTitle: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    fieldsOrder () {
      if (this.name === 'Amostras') {
        const f = this.orderGroups()
        return f
      } else {
        const f = this.items
        return f
      }
    }
  },
  methods: {
    orderGroups () {
      const orderItems = this.items.map(item => {
        const nItem = { ...item }

        if (nItem.groups && nItem.groups.length > 1) {
          nItem.groups = nItem.groups.sort((a, b) => {
            const order = {
              classe: 1,
              corte: 2,
              remessa: 3,
              arranjo: 4,
              origem: 5,
              localidade: 6,
              bucket: 7
            }

            return order[a.type] - order[b.type]
          })
        }

        return nItem
      })

      return orderItems
    }
  }
}
</script>
