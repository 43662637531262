<script>

import View from '@/mixins/core/ViewBehavior'
import Action from '@/mixins/core/ActionBehavior'
import Event from '@/mixins/core/EventBehavior'

import { toOptions, toast } from '@/utils'
import { request as api } from '@/services'

export default {
  mixins: [View, Action, Event],
  data: () => ({
    currentPage: 1,
    perPage: 8,
    sortBy: '',
    isFiltering: false,
    filterData: {},
    totalRows: 1,
    edit: null
  }),
  model: {
    prop: 'selected',
    event: 'onChange'
  },
  props: {
    // Model key for object 'models' from module
    modelKey: {
      type: String,
      required: false
    },
    // Dynamic fields from items
    dynamic: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Array,
      default: () => []
    },
    // need when selectable is true
    primaryFieldKey: {
      type: String,
      default: 'id'
    }
  },
  created () {
    this.totalRows = this.items.length
    // if (!this.selected.length) this.emitSelection([]) // avoid conflict in v-model
    if (this.state.get.lazy) {
      this.$store.dispatch(this.module + '/init', { params: this.$route.params, self: this })
    }
  },
  computed: {
    fields () {
      if (this.dynamic || !this.modelKey) { // If dynamic or modelKey not passed, convert items to a string array of unique values
        return this.items.map(item => Object.keys(item)).flat().filter((value, index, self) => self.indexOf(value) === index)
      }
      // Get model from modelKey
      return this.state.models[this.modelKey]
    },
    fieldsWithEvents () {
      const f = this.fields.slice()

      // Add events column if has any action for view
      if (this.actions.length > 0) f.push({ key: 'events', label: 'Ações' })

      // Checkbox
      if (this.selectable) f.unshift({ key: 'selected', label: ' ' })

      return f
    },
    sortOptions () {
      // For dynamic fields, filter options will be same as items
      // For manual fields, an object array will be generated from model
      return this.dynamic ? this.fields // Dynamic fields
        : toOptions(this.fields, { value: 'key', text: 'label' }) // Manual fields
    },
    isBusy () {
      return this.state.loading
    },
    empty () {
      return !(this.items.length || this.isBusy)
    },
    isAllRowsSelected () {
      return this.items.length === this.selected.length
    }
  },
  methods: {
    sort (key) {
      this.sortBy = key
    },
    filterSwitch (value) {
      this.isFiltering = value
      if (!value) this.filterData = {} // clear inputs when filter close
    },
    arrayFilter (str) {
      const match = str.match(/(\w+)\[(\d+)\]\.(\w+)/)
      if (match) {
        const arrayKey = match[1]
        const index = parseInt(match[2], 10)
        const property = match[3]
        return { arrayKey, index, property }
      }
      return null
    },
    filter (row, filters) {
      if (
        !this.isFiltering ||
        // Search for any "true value" on filters
        !Object.keys(filters).reduce((result, value) => result || !!filters[value], false)
      ) { return true }

      // One match verification by includes
      for (const key in filters) {
        if (row[key]?.toString().includes(filters[key]) && !!filters[key]) {
          return true
        }

        const nKey = this.arrayFilter(key)

        if (nKey && row[nKey.arrayKey] && row[nKey.arrayKey][nKey.index] && row[nKey.arrayKey][nKey.index][nKey.property]?.toString().includes(filters[key]) && !!filters[key]) {
          return true
        }
      }

      return false
    },
    onFiltered (filteredItems) {
      // console.log('filtered')
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    itemSelectedIndex (item) {
      return this.selected.findIndex(o => o[this.primaryFieldKey] === item[this.primaryFieldKey])
    },
    isItemSelected (item) {
      return !!(this.itemSelectedIndex(item) + 1)
    },
    onRowClicked (item) {
      const i = this.itemSelectedIndex(item)

      this.emitSelection(i === -1
        ? [...this.selected, item] // Insert
        : this.selected.filter((v, i2) => i - i2) // Remove
      )
    },
    switchSelection () {
      this.emitSelection(this.isAllRowsSelected ? [] : this.items.slice())
    },
    emitSelection (v) {
      this.$emit('onChange', v)
    },
    onEdit (id) {
      this.edit = this.edit !== id ? id : null
      console.log('edit: ' + id)
    },
    onLink (item, id) {
      const classe = item.groups.find(group => group.type === 'classe')?.key
      const corte = item.groups.find(group => group.type === 'corte')?.key
      const remessa = item.groups.find(group => group.type === 'remessa')?.key
      const arranjo = item.groups.find(group => group.type === 'arranjo')?.key
      const origem = item.groups.find(group => group.type === 'origem')?.key
      const localidade = item.groups.find(group => group.type === 'localidade')?.key

      this.$router.push({
        name: 'classificar',
        params: {
          classe: classe,
          corte: corte,
          remessa: remessa,
          arranjo: arranjo,
          origem: origem,
          localidade: localidade,
          object_id: id
        }
      })
    },
    // temp
    onSave (item, id) {
      this.edit = this.edit !== item ? item : null
      console.log('save: ' + JSON.stringify(item))
      api.put('variable/update/' + id, item, response => {
        console.log(response)
        if (response.status === 200) {
          this.$store.dispatch('variables/edit', item)
          toast(
            this,
            'success',
            'Variável atualizada',
            'Variável atualizada com sucesso'
          )
        }
      },
      error => {
        toast(
          this,
          'danger',
          'Variável não atualizada',
          error.message +
              (error.response.status === 422
                ? ' => ' + error.response.data.errors
                : '')
        )
        return Promise.reject(error)
      })
    }
  }
}
</script>
