import { baseMutations, baseActions } from '../functions'

export default {
  namespaced: true,
  state: {

    // Info
    items: [],
    name: 'Classificar',
    loading: true,
    noMenu: true,

    menu: {
      title: 'Classificar Imagens'
    },

    // Routes - Components actions
    actions: {
      // Main Action
      component: 'Cards.Classificar',
      params: ['classe', 'corte', 'remessa', 'arranjo', 'origem', 'localidade', 'object_id'],
      props: route => ({
        classe: route.params.classe,
        corte: route.params.corte,
        remessa: route.params.remessa,
        arranjo: route.params.arranjo,
        origem: route.params.origem,
        localidade: route.params.localidade,
        object_id: route.params.object_id,
        title: 'Classificar Análise',
        module: 'classificar',
        modelKey: 'card'
      }),
      children: [
      ]
    },

    // API Get index
    get: {
      urn: '/extra_field/index/',
      response: 'extra_field_values'
    },

    // API routes
    requests: {
    },

    // Models
    models: {
      card: [
        { key: 'image', slot: 'image' }
      ]
    },

    // Forms
    forms: {
    }

  },

  mutations: Object.assign({}, baseMutations, {
    SET_URL (state, payload) {
      const {
        urls
      } = payload
      state.items = urls.map(item => ({
        url: item
      }))
      state.loading = false
    },
    SET_IMAGE (state, payload) {
      const {
        images
      } = payload
      state.items = [{ image: images }]
      state.loading = false
    }
  }),
  actions: Object.assign({}, baseActions, {
    // ...
  })
}
