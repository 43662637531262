<template>
    <Builder :title="title" :values="values" v-slot="props" @add="add">

        <div class="d-flex flex-row align-items-baseline">

            <b-form-group v-for="f in fields" :key="f.index" :label="f.label">
                <b-form-input :value="get(props.value, f.key)" @input="input($event, f.key, props.index)"></b-form-input>
            </b-form-group>

            <div class="button mx-2" style="background-color: #BD1B24" @click="del(props.index)"></div>

        </div>
    </Builder>
</template>

<style lang="stylus" scoped>

@import '../../../style/colors.styl';
@import '../../../style/fonts.styl';

  fieldset
    margin-left 3%

</style>
<script>

import Builder from './Builder'
import Behavior from './Behavior'

export default {
  components: { Builder },
  mixins: [Behavior],
  props: { form: { type: Object }, title: { type: String }, systemId: { type: String } },
  computed: {
    field () {
      return this.fields
    }
  }
}
</script>
