<template>
    <div>
        <Title :title="title"></Title>
        <Interface
            :lineLabel="name" :sortOptions="sortOptions"
            @sort="sort" @filter="filterSwitch"
        >
            <div class="mb-3">
                <template v-if="!isBusy && !empty">

                    <!-- Filter -->
                    <div v-if="isFiltering" class="filters justify-content-start align-items-center mb-3">
                        <div v-for="field in fields" :key="field.key" class="px-3" >
                            <div>{{ field.label }}</div>
                            <input
                                type="text" class="form-control" :id="'filter-' + field.key"
                                v-model="filterData[field.key]"
                            />
                        </div>
                    </div>

                    <!-- Card -->
                    <b-row :cols="row" class="mb-4">
                        <b-col v-for="item in items" :key="item.id" v-show="filter(item, filterData)" class="card-column" :cols="12 / row">
                            <b-card class="card">
                                <slot :item="item" :values="itemValues(item)"></slot>
                            </b-card>
                        </b-col>
                    </b-row>

                    <!-- Paginator -->
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        size="md"
                        align="right"
                        class="my-0"
                    ></b-pagination>

                </template>
                <template v-else-if="empty">
                    <div class="text-center my-2">Não há registros até o momento.</div>
                </template>

                <template v-else>
                    <Loading></Loading>
                </template>
            </div>
        </Interface>
    </div>
</template>

<style lang="stylus">

@import '../../style/colors.styl';

.card
    background-color white

.card-column
    padding 10px 15px

.filters
    display flex // not use .d-flex cause !important override v-show

</style>

<script>

import Table from '@/mixins/sets/TableBehavior'

import Title from '@/components/holders/Title'
import Interface from '@/components/holders/Interface'

import Loading from '@/components/static/Loading'

export default {
  mixins: [Table],
  components: { Title, Interface, Loading },
  props: {
    // Number of cards in a row
    row: {
      type: Number,
      default: 2
    }
  },
  methods: {
    itemValues (item) {
      return this.fields.reduce((obj, value) => Object.assign(obj, { [value.slot]: item[value.key] }), {})
    }
  }
}
</script>
