<template>
    <div>
      <p>{{ title }}:</p>
        <b-row :cols="2" class="mb-4">
            <b-col v-for="(value, index) in values" :key="index">
                <slot :value="value" :index="index"></slot>
            </b-col>
        </b-row>
        <slot name="footer">
            <div @click="add" class="button">
                <i class ="menu-icon material-icons-outlined">add</i>
            </div>
        </slot>
    </div>
</template>

<style lang="stylus" scoped>

@import '../../../style/colors.styl';
@import '../../../style/fonts.styl';

.button
    display inline-flex
    cursor pointer
    padding 10px
    border-radius 50%
    text-align center
    color #ffffff
    transition .25s
    background-color warning
    min-width 40px
    min-height 40px

    i
        font-size 20px

    &:hover
        background-color white
        border-color: white
        text warning

</style>
<script>

export default {
  props: {
    values: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: false
    }
  },
  methods: {
    add () {
      this.$emit('add')
    }
  }
}
</script>
