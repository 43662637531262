<template>
    <div>
        <Title :title="title" :classificar="true"></Title>
        <Interface
            :noHeader="true"
            :lineLabel="name" :sortOptions="sortOptions"
            >
            <div class="mb-12">
                <template v-if="!isBusy && !empty">

                    <!-- Card -->
                    <b-row :cols="row" class="mb-4">
                        <b-col v-for="item in items" :key="item.id" class="card-column" :cols="12">
                            <b-card class="card">
                                <slot :item="item" :values="itemValues(item)"></slot>
                            </b-card>
                        </b-col>
                    </b-row>
                </template>

                <template v-else-if="empty">
                    <div class="text-center my-2">Não há registros até o momento.</div>
                </template>

                <template v-else>
                    <Loading></Loading>
                </template>

            </div>
        </Interface>
    </div>
</template>

<style lang="stylus">

@import '../../style/colors.styl';

.card-body
    background-color secondary

.card-title
    color primary

</style>

<script>
import { request as api } from '@/services'

import Table from '@/mixins/sets/TableBehavior'

import Title from '@/components/holders/Title'
import Interface from '@/components/holders/Interface'

import Loading from '@/components/static/Loading'

import { dateIot } from '@/utils'

export default {
  mixins: [Table],
  components: { Title, Interface, Loading },
  props: {
    // Number of cards in a row
    row: {
      type: Number,
      default: 2
    }
  },
  async created () {
    await this.getData()
  },
  methods: {
    dateIot,
    async getData () {
      // Local import
      const link = this.$route.params
      const date = this.dateIot()

      const requestConfig = {
        urn: `/analisar?classe=${link.classe}&corte=${link.corte}&remessa=${link.remessa}&arranjo=${link.arranjo}&origem=${link.origem}&localidade=${link.localidade}&bucket=${link.bucket}`,
        response: 'data'
      }

      api.getIot(requestConfig.urn, response => {
        if (response.data.imagens) {
          const images = []
          for (const key in response.data) {
            if (Array.isArray(response.data[key])) {
              response.data[key].forEach(item => {
                const isJPEG = item.toLowerCase().endsWith('.jpeg')// && item.toLowerCase().includes('semfiltro')
                if (isJPEG) {
                  item = `${date}/${link.classe}.${link.corte}.${link.remessa}.${link.arranjo}.${link.origem}.${link.localidade}/` + item
                  images.push(item)
                }
              })
            }
          }
          this.$store.commit('classificar/SET_IMAGE', {
            images
          })
        }
      },
      error => {
        console.log(error)
        // URL S3 import
        const urn = this.$store.state.classificar.get.urn + this.$route.params.object_id

        // Create Item
        api.get(urn, response => {
          if (response.data !== undefined) {
            const urls = []
            for (const key in response.data) {
              if (Array.isArray(response.data[key])) {
                response.data[key].forEach(item => {
                  const isJPEG = item.toLowerCase().endsWith('.jpeg')// && item.toLowerCase().includes('semfiltro')
                  if (isJPEG) {
                    urls.push(item)
                  }
                })
              }
            }

            this.$store.commit('classificar/SET_URL', {
              urls
            })
          } else {
            console.error('Propriedade "data" não está definida.')
          }
        },
        error => {
          console.log(error)
        })
      })
    },
    itemValues (item) {
      return this.fields.reduce((obj, value) => Object.assign(obj, { [value.slot]: item.slot }), {})
    }
  }
}
</script>
