import { baseMutations, baseActions } from '../functions'

export default {

  namespaced: true,
  state: {
    // Info
    items: [],
    name: 'Ativos',
    loading: true,
    noMenu: true,

    // Routes - Components actions
    actions: {
      // Main Action
      component: 'Cards.Actives',
      props: { title: 'Ativos', module: 'ativesUser' },
      params: ['userId'],
      children: [
        {
          key: 'modal2',
          scope: 'Buttons',
          options: { theme: 'success' }
        },
        {
          key: 'modal3',
          scope: 'Buttons',
          options: { theme: 'danger' }
        },
        {
          key: 'userId',
          props: route => ({ userId: route.params.userId, requests: this.userId !== undefined ? 'assetsUser' : null })
        }
      ]
    },

    // API Get index
    get: {
      urn: '/system/assets/index?user_id=:userId',
      response: 'assets',
      lazy: true
    },

    // API routes
    requests: {
    },

    // Models
    models: {
    },

    // Forms
    forms: {
    }
  },
  mutations: Object.assign({}, baseMutations, {
    // ...
  }),
  actions: Object.assign({}, baseActions, {
    // ...
  })
}
