<script>

// Base structure for dynamic generated form
// This component must have to has a parent component to trigger submit

import { toOptions, get, objectFilter } from '@/utils'
import { request as api } from '@/services'

import View from '@/mixins/core/ViewBehavior'

// Special components as fields
import Table from '@/components/views/Table'
import Single from '@/components/static/Inputs/Single'
import Draw from '@/components/static/Inputs/Draw'
import LoadingPage from '@/components/static/LoadingPage'

import Multiselect from 'vue-multiselect'

const special = {
  Table: Table,
  'Inputs.Single': Single,
  'Inputs.Draw': Draw
}

export default {
  mixins: [View],
  components: {
    LoadingPage,
    Multiselect,
    ...(Object.keys(special).reduce((obj, c) => ({ ...obj, [c]: special[c] }), {}))
    // ...
  },
  created () {
    this.special = special // avoid data to not do it reactive
  },
  data: () => ({
    loading: false,
    form: {
      classe: {
        options: [],
        value: []
      },
      bucket: {
        options: [],
        value: []
      },
      corte: {
        options: [],
        value: []
      },
      remessa: {
        options: [],
        value: []
      },
      arranjo: {
        options: [],
        value: []
      },
      origem: {
        options: [],
        value: []
      },
      localidade: {
        options: [],
        value: []
      }
    }
  }),
  computed: {
    fields () { return this.state.forms[this.formKey] },
    hiddenFields   () { return this.fields.filter(f => f.type === 'hidden') },
    editableFields () { return this.fields.filter(f => ['hidden', 'urn', 'response'].indexOf(f.type) < 0) },
    item () { return this.itemId ? this.state.items.find(item => item.id === this.itemId) : null }
  },
  mounted () {
    // Table Select field init
    this.fields.filter(f => f.type === 'view' && f.view === 'Table').forEach(f => {
      this.form = Object.assign({}, this.form, { [f.key]: [] })
    })

    // Item
    if (this.itemId) {
      // this.loading = true
      this.$watch('$store.state.' + this.module + '.items', () => {
        // Item object to Form
        this.form = Object.assign({}, this.form,
          // Ignore Null
          Object.keys(this.item || {}).reduce((obj, key) =>
            this.item[key] === null && this.fields.find(f => f.key === key)?.ignoreNull ? obj : ({ ...obj, [key]: this.item[key] }),
          {})
        )
        if (this.item) this.fields.filter(f => f.onChange).forEach(f => this.selectChange(f))
        // this.loading = false
      }, { immediate: true })
    }

    // Selects
    this.loadOptions()
  },
  props: {
    itemId: {
      required: false,
      type: String
    },
    formKey: {
      required: true,
      type: String
    },
    requests: {
      required: true,
      type: String
    }
  },
  methods: {
    async loadOptions () {
      // Selects
      try {
        const requestConfig = {
          urn: '/group/public/index',
          response: 'data'
        }

        const selects = ['classe', 'corte', 'remessa', 'arranjo', 'origem', 'localidade', 'bucket']

        api.get(requestConfig.urn, response => {
          const data = response[requestConfig.response]

          if (data !== undefined) {
            // Selects
            selects.forEach(tipo => {
              const filteredOptions = data.groups.filter(group => group.type === tipo)

              filteredOptions.forEach(group => {
                this.form[tipo].options.push(
                  { description: group.description, key: group.key }
                )
              })
            })
          } else {
            console.error('Propriedade "data" não está definida.')
          }
        }, error => {
          console.log(error)
        })
      } catch (error) {
        console.error('Erro ao buscar opções:', error)
      }
    },
    selectOptions (field) {
      // build options to select Fields
      const self = this

      return ({
        static: (field) => field.options,
        store: (field) => toOptions(get(self.$store.state, field.value), field.options)
      })[field.from](field)
    },
    getValue (field) {
      // Get value
      const self = this

      this.form[field.key] = ({
        static: (v) => v,
        store: (v) => get(self.$store.state, v),
        item: (v) => self.item?.[v],
        route: (v) => self.$route.params?.[v]
      })[field.from](field.value)

      return this.form[field.key]
    },
    selectChange (field) {
      // Select on change reflect another field
      const onChange = field.onChange

      // No action for onChange
      if (!onChange) return

      const self = this

      ;({
        request: (v) => {
          api.get(v.urn.replaceAll(':value', self.form[field.key]), response => {
            // Object assign for work reactivity in vue
            self.form = Object.assign({}, self.form, { [onChange.bind]: response.data[v.response] })
          }, error => {
            // another type of emit
            console.log(error)
          })
        }
      })[onChange.type](onChange.options)
    },
    submit () {
      // Form Validation
      if (!this.$refs.form.checkValidity()) {
        this.$emit('invalid', 'Preencha os campos corretamente')
        return
      }

      const self = this
      const logs = []

      self.loading = true

      // Call requests function recursively
      ;(function call (requests, depth = 0, responses = []) {
        const fields = self.fields.filter(f => (f.depth ?? 0) === depth)
        const body = objectFilter(self.form, k => fields.reduce((v, f) => [...v, f.key], []).includes(k))
        const request = requests[depth]
        const urn = fields.filter(f => f.type === 'urn').reduce((urn, f) => urn.replaceAll(':' + f.key, self.getValue(f)), request.urn)

        // Add fields 'response' to body
        fields.filter(f => f.type === 'response').forEach(f => {
          body[f.key] = responses[f.from][f.value]
        })

        // Formatter
        fields.filter(f => f.formatter).forEach(f => {
          body[f.key] = f.formatter(body[f.key])
        })

        // Send request
        api[request.method](urn, body, response => {
          // Response data
          const data = request.response ? response.data[request.response] : response.data

          // Vuex dispatch
          if (request.dispatch) self.$store.dispatch(self.module + '/' + request.dispatch, data)

          // Log success
          logs.push({ status: 'success', title: request.title, msg: request.success })

          // Call next request
          if (depth < requests.length - 1) call(requests, depth + 1, [...responses, data])

          // if last request in queue emit a event
          if (depth === requests.length - 1) {
            self.loading = false
            self.$emit('finished', true, logs, responses)
            // self.$router.push({
            //   path: '/images/' +
            //   self.form.classe.value.key + '/' +
            //   self.form.corte.value.key + '/' +
            //   self.form.remessa.value.key + '/' +
            //   self.form.arranjo.value.key + '/' +
            //   self.form.origem.value.key + '/' +
            //   self.form.localidade.value.key + '/' +
            //   self.form.bucket.value.key
            // })
          }
        },
        error => {
          // Log failure
          self.loading = false
          logs.push({ status: 'failed', title: 'Error ' + request.title, msg: error })
          self.$emit('finished', false, logs, responses)
        })
      })(this.requests.split('.').reduce((list, value) => [...list, self.state.requests[value]], [])) // List of object requests from module
    },
    addNewOption (field, newOption) {
      const self = this

      const newOptionData = {
        key: newOption,
        description: newOption,
        type: field.key,
        public: 1,
        master_group_id: field.id
      }

      const newOptionTag = {
        key: newOption,
        description: newOption
      }

      self.form[field.key].options.push(newOptionTag)
      self.form[field.key].value = newOptionTag

      try {
        const requestConfig = {
          urn: '/group/create'
        }

        api.post(requestConfig.urn, newOptionData, response => {
          console.log('Resposta ao criar grupo:', response.data)
        },
        error => {
          console.error('Erro ao criar grupo, erro:', error)
        })
      } catch (error) {
        console.error('Erro ao criar grupo:', error)
      }
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<template>
  <div>
    <!-- Loading -->
    <LoadingPage v-if="loading"></LoadingPage>

    <b-form ref="form" @submit.stop.prevent="submit">
        <b-row>
            <b-col
                v-for="field in editableFields" :key="field.key"
                :cols="field.col"
            >
                <!-- Normal fields -->
                <b-form-group v-if="field.type !== 'view'"
                    :id="'input-group-' + field.key"
                    :label="field.label + ':'"
                    :label-for="'input-' + field.key"
                >
                    <b-form-input style="background-color: #F5F5F5; border-color: #3C762C;" v-if="['label'].includes(field.type)" v-model="form[field.key]" :run="form[field.key] = getValue(field)" disabled/>

                    <b-form-input style="background-color: #F5F5F5; border-color: #3C762C;" v-if="['text', 'number', 'email', 'password'].includes(field.type)"
                    :id="'input-' + field.key"
                    v-model="form[field.key]"
                    :type="field.type"
                    :min="field.min"
                    :max="field.max"
                    v-mask="field.mask"
                    required
                    ></b-form-input>

                    <b-form-textarea  style="background-color: #F5F5F5; border-color: #3C762C;" v-if="field.type === 'textarea'"
                    :id="'input-' + field.key"
                    v-model="form[field.key]"
                    placeholder="Conteúdo ..."
                    rows="3"
                    max-rows="12"
                    ></b-form-textarea>

                    <template v-if="field.type === 'date'">
                      <b-form-datepicker :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" id="input-datepicker" v-model="form[field.key]" class="mb-2"></b-form-datepicker>
                    </template>

                    <b-form-select v-if="field.type === 'select-sample'"
                      v-model="form[field.key].value"
                    >
                    <template #first>
                      <option disabled>-- Selecione um {{ field.label }} --</option>
                    </template>
                    <template>
                      <option v-for="option in form[field.key].options" :key="option.key" :value="option">
                        {{ option.description }}
                      </option>
                    </template>
                    </b-form-select>

                    <b-form-select v-if="field.type === 'select'"
                        v-model="form[field.key]"
                        :options="selectOptions(field)" :multiple="!!field.multiple"
                        @change="selectChange(field, $event)"
                    >
                        <template #first>
                            <b-form-select-option :value="undefined" disabled>-- Selecione um {{ field.label }} --</b-form-select-option>
                        </template>
                    </b-form-select>

                    <multiselect v-if="field.type === 'multi-select'"
                      v-model="form[field.key].value"
                      tag-placeholder="-- Adicionar nova Opção --"
                      placeholder="-- Selecione uma Opção --"
                      deselectLabel="-- Pressione Enter para Remover --"
                      :options="form[field.key].options"
                      :multiple="!!field.multiple"
                      @change="selectChange(field, $event)"
                      @tag="addNewOption(field, $event)"
                      :ref="'multiselect-' + field.key"
                      :searchable="true"
                      :taggable="true"
                      label='description'
                    >
                    </multiselect>

                </b-form-group>

                <!-- View Fields -->
                <template v-if="field.type === 'view'">
                    <component :is="special[field.view]" v-bind="field.props" v-model="form[field.key]" :form="form" :field="field"></component>
                </template>

            </b-col>
        </b-row>
        <div v-for="field in hiddenFields" :key="field.key">
            <input type="hidden" :value="getValue(field)"/>
        </div>
    </b-form>

  </div>
</template>
