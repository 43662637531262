<template>
    <div>
        <Title :title="title"></Title>
        <h5>{{ getSample() }}</h5>
        <Interface
            :noHeader="true"
            :lineLabel="name" :sortOptions="sortOptions"
            >
            <div class="mb-3">
                <template v-if="!isBusy && !empty">

                    <!-- Card -->
                    <b-row :cols="row" class="mb-4">
                        <b-col v-for="item in items" :key="item.id" class="card-column" :cols="4">
                            <b-card class="card">
                                <slot :item="item" :values="itemValues(item)"></slot>
                            </b-card>
                        </b-col>
                    </b-row>
                </template>

                <template v-else-if="empty">
                    <div class="text-center my-2">Não há registros até o momento.</div>
                </template>

                <template v-else>
                    <Loading></Loading>
                </template>

            </div>
        </Interface>
    </div>
</template>

<style lang="stylus">

@import '../../style/colors.styl';

.card-body
    background-color secondary

.card-title
    color primary

.card-column
    padding 10px 15px

</style>

<script>
import { request as api } from '@/services'

import Table from '@/mixins/sets/TableBehavior'

import Title from '@/components/holders/Title'
import Interface from '@/components/holders/Interface'

import Loading from '@/components/static/Loading'

import { dateIot } from '@/utils'

export default {
  mixins: [Table],
  components: { Title, Interface, Loading },
  props: {
    // Number of cards in a row
    row: {
      type: Number,
      default: 2
    }
  },
  mounted () {
    this.imagesLoad()
  },
  methods: {
    dateIot,
    getSample () {
      const keys = Object.keys(this.$route.params)
      const values = keys.map(key => this.$route.params[key])
      return values.join(' / ')
    },
    async imagesLoad () {
      const date = this.dateIot()

      // Images
      try {
        const link = this.$route.params

        const requestConfig = {
          urn: `/analisar?classe=${link.classe}&corte=${link.corte}&remessa=${link.remessa}&arranjo=${link.arranjo}&origem=${link.origem}&localidade=${link.localidade}&bucket=${link.bucket}`,
          response: 'data'
        }

        api.getIot(requestConfig.urn, response => {
          const data = response[requestConfig.response]

          if (data !== undefined) {
            const newItems = data.imagens
            this.$store.commit('images/SET_ITEMS', {
              newItems,
              classe: link.classe,
              corte: link.corte,
              remessa: link.remessa,
              arranjo: link.arranjo,
              origem: link.origem,
              localidade: link.localidade,
              bucket: link.bucket,
              date
            })
          } else {
            console.error('Propriedade "data" não está definida.')
          }
        })
      } catch (error) {
        console.error('Erro ao carregar imagens:', error)
      }
    },
    itemValues (item) {
      return this.fields.reduce((obj, value) => Object.assign(obj, { [value.slot]: item.slot }), {})
    }
  }
}
</script>
