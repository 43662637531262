<template>
    <div class="interface">
      <b-card class="edit-card">
        <div class="logo">
              <img class="logo-menu" src="@/assets/images/logo-agritest.svg" @click="$router.push('/')" />
          </div>

        <b-button @click="$router.push('/')" class="title-btn">
          <i class="icon-btn material-icons">arrow_back</i>Voltar
        </b-button>
          <div>
                <h3 class="title">Editar Perfil</h3>
              <b-form class="form-row" @submit.prevent="editProfile">

                <div class="form-group col-md-6">
                  <label class="edit-label">Nome:</label>
                  <b-form-input
                      v-model="user.name"
                      type="text"
                      placeholder="Nome"
                  ></b-form-input>
                </div>

                <div class="form-group col-md-6">
                  <label class="edit-label">E-mail:</label>
                  <b-form-input
                      v-model="user.email"
                      type="email"
                      placeholder="E-mail"
                  ></b-form-input>
                </div>

                <div class="form-group col-md-6">
                  <label class="edit-label">Documento:</label>
                  <b-form-input
                      v-model="user.document"
                      type="text"
                      placeholder="Documento"
                  ></b-form-input>
                  </div>

                <div class="form-group col-md-6">
                  <label class="edit-label">Telefone:</label>
                  <b-form-input
                      v-model="user.telephone"
                      type="tel"
                      placeholder="Telefone"
                  ></b-form-input>
                </div>

                <div v-if="extra_field_id !== ''" class="form-group col-md-6">
                  <label class="edit-label">Fuso horário:</label>
                  <b-form-select
                      v-model="selected"
                      :options="timezone_options"
                      class="form-select"
                  ></b-form-select>
                  </div>

                <b-button class="password-button" v-b-modal.modal-multi-1>Alterar Senha</b-button>
                  <b-modal body-class="my-class" id="modal-multi-1" size="md" title="Alterar senha" centered no-stacking hide-header hide-footer>
                    <p class="my-2">Insira seus dados para receber a chave por e-mail:</p>
                    <label>CPF:</label>
                    <b-form-input
                      v-model="change_password.cpf"
                      type="text"
                      placeholder="CPF"></b-form-input><br>
                      <label>E-mail:</label>
                    <b-form-input
                      v-model="change_password.email"
                      type="text"
                      placeholder="E-mail"></b-form-input>
                    <b-button class="ok" @click="requestRecoverPasswordCode">Ok</b-button>
                  </b-modal>

                   <b-modal body-class="my-class" id="modal-multi-2" title="Alterar senha" size="md" centered no-stacking  hide-header hide-footer>
                    <p class="my-2">Insira a chave recebida no e-mail:</p>
                    <b-form-input
                      v-model="change_password.code"
                      type="text"
                      placeholder="Chave"></b-form-input>
                    <b-button class="ok" @click="sendRecoverPasswordCode">Ok</b-button>
                  </b-modal>

                  <b-modal body-class="my-class" id="modal-multi-3" title="Alterar senha" size="md" centered no-stacking hide-header hide-footer>
                    <p class="my-2">Nova senha:</p>
                    <b-form-input
                      v-model="change_password.new_password"
                      type="text"
                      placeholder="Nova senha"></b-form-input>
                    <p class="my-1">Repita a nova senha:</p>
                    <b-form-input
                      v-model="change_password.new_password_confirm"
                      type="text"
                      placeholder="Nova senha"></b-form-input>
                    <b-button class="ok" @click="resetPassword">Ok</b-button>
                  </b-modal>

                <b-button type="submit" class="title-btn save">
                  <i class="icon-btn material-icons"></i>Salvar
                </b-button>
              </b-form>
          </div>
      </b-card>
  </div>
</template>

<style lang="stylus" scoped>

    @import '../style/colors.styl';
    @import '../style/fonts.styl';

    /deep/ .my-class
      border-radius: 50px
      background: white
      color: black
      border-color gray

    .ok
      background-color primary
      margin-top 1%
      margin-left 90%

    .title-btn
      background-color: warning
      border-color: white

      &:hover
        border-color: warning
        background-color: white
        color: warning

    .modal-content
      background-color white
      border-radius 50px
      color black
      font-size 18px
      font-weight 600
      line-height 20px

    .modal-body
      border-radius 8px
      margin 5px
      background-color primary

    .modal-header, .modal-footer
      border-color #171819

    .modal
      text-align: center

    .password-button
      width fit-content
      margin-top 5%
      background-color primary

    .toggle-collapse
      margin-top 5%
      width 20%

    .logo
      margin-bottom: 10%;

    .save
      align-content flex-end
      margin-top 5%
      width fit-content
      margin-left auto

    .edit-label
      margin-top 5%

    .form-control
      margin 0%

    .interface
        min-height 100%
        min-height 100vh
        display blockS
        align-items center
        background-color secondary

    .edit-card
        width 70%
        min-height 81% // old support
        min-height 81vh
        padding 5%
        padding-top 5%
        margin 0 auto
        display block
        background white

    .logo-menu
        cursor pointer
    .card-body
        background-color: white

    form
        width 100%

        *
            font-family Roboto-Regular

        .title
            text-align start
            font-size 28px
            color primary

        input, button
            height calc(1.5em + .75rem + 2px)
            padding .4rem 1rem

        input
            margin 25px 0
            border-radius: 50rem

        button
            width 100%

</style>

<script>

import { request } from '@/services'
import { toast } from '@/utils'

export default {
  data: () => ({
    user: {
      name: '',
      email: '',
      document: '',
      telephone: ''
    },
    change_password: {
      cpf: '',
      email: '',
      code: '',
      new_password: '',
      new_password_confirm: ''
    },
    title: 'Perfil',
    info: null,
    selected: null,
    extra_field_id: '',
    timezone_options: [
      {
        value: null,
        text: 'Não selecionado'
      },
      {
        value: 'America/Noronha',
        text: 'Fernando de Noronha'
      },
      {
        value: 'America/Sao_Paulo',
        text: 'Brasília'
      },
      {
        value: 'America/Manaus',
        text: 'Amazonas'
      },
      {
        value: 'America/Rio_Branco',
        text: 'Acre'
      }
    ]
  }),
  mounted () {
    this.getUser()
  },
  props: ['userId'],
  methods: {
    async requestRecoverPasswordCode () {
      this.info = null
      this.info = {
        cpf: this.change_password.cpf,
        email: this.change_password.email
      }
      request.post('/recover/request', this.info, response => {
        this.$bvModal.hide('modal-multi-1')
        this.$bvModal.show('modal-multi-2')
      }, error => {
        this.$bvModal.hide('modal-multi-1')
        toast(this, 'danger', 'Erro ao enviar código.', error)
      })
    },
    async sendRecoverPasswordCode () {
      this.info = null
      this.info = {
        cpf: this.change_password.cpf,
        email: this.change_password.email,
        code: this.change_password.code
      }
      request.post('/recover/confirm', this.info, response => {
        this.$bvModal.hide('modal-multi-2')
        this.$bvModal.show('modal-multi-3')
      }, error => {
        this.$bvModal.hide('modal-multi-2')
        toast(this, 'danger', 'Erro.', error)
      })
    },
    async resetPassword () {
      this.info = null
      this.info = {
        password: this.change_password.new_password,
        password_confirmation: this.change_password.new_password_confirm
      }
      request.post('/reset_password', this.info, response => {
        this.$bvModal.hide('modal-multi-3')
        if (response.status === 200) {
          toast(
            this,
            'success',
            'Senha alterada.',
            'Senha atualizada com sucesso'
          )
        }
      }, error => {
        this.$bvModal.hide('modal-multi-3')
        toast(this, 'danger', 'Erro.', error)
      })
    },
    async getUser () {
      request.get('/user/show/' + this.userId, response => {
        this.user = response.data.user
        if (response.data.user.extra_fields !== undefined || response.data.user.extra_fields !== null) {
          this.selected = response.data.user.extra_fields[0].value.value
          this.extra_field_id = response.data.user.extra_fields[0].value.extra_field_id
        }
      }, error => {
        toast(this, 'danger', 'Erro ao carregar os dados.', error)
      })
    },
    async editProfile () {
      if (this.extra_field_id !== '') {
        this.$set(this.user, this.extra_field_id, this.selected.value)
      }
      request.post('/auth/update/' + this.userId, this.user, response => {
        this.$router.replace({ path: '/' })
        if (response.status === 200) {
          toast(
            this,
            'success',
            'Perfil editado com sucesso',
            'Perfil atualizado com sucesso'
          )
        }
      }, error => {
        toast(this, 'danger', 'Erro ao editar perfil.', error)
      })
    }
  }
}
</script>
