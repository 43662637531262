<template>
    <div class="interface-content">

        <!-- Interface Header -->
        <div class="interface-header" v-if="!noHeader">

            <!-- Button Event -->
            <b-button variant="success" v-if="btnAction" @click="event(btnAction)">
                <i class="material-icons">{{ btnAction.options.btnIcon || 'add' }}</i>
            </b-button>

            <!-- Filter -->
            <span v-if="!noFilter" @click="filterSwitch" :class="'filter' + (filter ? ' actived' : '')">
                <i class="material-icons-outlined">filter_alt</i>
                Filtro<!--
            --></span>

            <!-- if Filter && OrderBy -->
            <span v-if="!noFilter" class="v-separator"></span>

            <!-- Order By -->
            <span v-if="!noOrder" class="orderby">Ordenar por</span>
            <b-form-select
                v-if="!noOrder"
                id="sort-by-select"
                @change="orderByChange"
                :options="sortOptions"
                v-model="sortBy"
                class="select"
            >
                <template #first>
                    <option value="">-- Nenhum campo selecionado --</option>
                </template>
            </b-form-select>

            <!-- Line Separator -->
            <HLine :label="lineLabel" class="mt-4"></HLine>

        </div>

        <!-- Slot Content -->
        <div class="slot-content">
            <slot></slot>
        </div>

    </div>
</template>

<script>

/**
 * Component Holder - Interface
 *
 * Slot for content, has a header with a button event, a filter toggle
 * and a select for order by as static events
 *
 * Events:
 *   - Button on header in left (no suffix needed)
 *       + Options:
 *           - btnIcon(String) default 'add'
 *
 * Static Events:
 *   - Toggle Filter 'filter'
 *   - Select Order by 'sort'
 *
 * Props:
 *   - Display header 'noHeader' default 'false'
 *       + if true no events will be trigger as well as header not shown
 *   - Order by Options 'sortOptions' not required
 *       + if not pass orderby will not be shown
 *   - Line separator label 'lineLabel' not required
 *       + 'noHeader' must be false to Line separator be shown
 *
 *  Obs: add more fields case additional customization are needed
 */

import Action from '@/mixins/core/ActionBehavior'
import Event from '@/mixins/core/EventBehavior'

import HLine from '@/components/static/Line'

export default {
  mixins: [Action, Event],
  components: { HLine },
  data: () => ({
    actionsScope: 'Interface',
    sortBy: '',
    filter: false
  }),
  props: {
    noHeader: { type: Boolean, default: false },
    noFilter: { type: Boolean, default: false, required: false },
    noOrder: { type: Boolean, default: false, required: false },
    sortOptions: { type: Array, required: false },
    lineLabel: { type: String, required: false }
  },
  methods: {
    filterSwitch () {
      this.filter = !this.filter
      this.$emit('filter', this.filter)
    },
    orderByChange (e) {
      this.$emit('sort', e)
    }
  },
  computed: {
    btnAction () {
      return this.actions[0]
    }
  }
}
</script>

<style lang="stylus" scoped>

    @import '../../style/colors.styl';
    @import '../../style/fonts.styl';
    @import '../../style/mixins.styl';

    .title-btn
        float right
        background-color primary
        color white
        border-radius 8px
        padding 6px 12px
        cursor pointer
        transition 0.2s
        // margin-top -10px

    .interface-content
        background-color secondary
        padding 16px 15px 16px 0px
        border-radius 8px
        color color-theme(true)

        .interface-header
            padding 16px

            span
                font-size 21px
                vertical-align middle

            .filter
                cursor pointer

                &:hover
                    color primary

            .actived
                color primary

            i
                font-size 28px
                vertical-align sub

            .v-separator
                background-color color-theme(true)
                display inline-block
                width 1px
                height 25px
                margin 0 8px
                cursor default

        .slot-content
            padding 0 0 0 16px

        .select
            width auto
            float right

</style>
