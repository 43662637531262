<template>
  <Card v-bind="$props" v-slot="props">
      <b-card-title class="header">
          <div class="d-flex justify-content-between align-items-center">
              <span>{{ props.values.title }}</span>

              <!-- Events -->
              <Buttons :data="props.item"></Buttons>
          </div>
      </b-card-title>

      <!-- Body Text -->
      <b-card-text>{{ props.values.body }}</b-card-text>

      <!-- event -->
      <!--<div class="btn-show">Ver Mais</div>-->

      <!-- Updated at -->
      <b-card-text>{{ dateFormat(props.item['updated_at']) }}</b-card-text>

  </Card>
</template>

<style lang="stylus" scoped>

@import '../../../style/colors.styl';
@import '../../../style/mixins.styl';

  .header
    color #4472C4
    border-bottom 1px dashed #25292d
    padding-bottom 15px
    margin-bottom 15px
    min-height 60px
    vertical-align middle

  .header p
    text-overflow: ellipsis

  .btn-show
    float right
    margin-top -15px
    border-radius 32px
    background-color content-theme()
    padding 8px 16px
    color #ffffff
    cursor pointer

</style>

<script>

import Card from '@/components/builders/Card'

import Buttons from '@/components/holders/Buttons'

import View from '@/mixins/core/ViewBehavior'
import Action from '@/mixins/core/ActionBehavior'

import { dateFormat } from '@/utils'

export default {
  mixins: [View, Action],
  components: { Card, Buttons },
  props: ['modelKey'],
  methods: {
    dateFormat
  }
}
</script>
