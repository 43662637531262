<template>
    <div>
    <nav>
      <div class="logo-nav">
      <img alt="Agritest Logo" src="@/assets/images/logo-agritest.svg">
    </div>
    </nav>
    <div class="interface">
      <img class="image" src="@/assets/images/fundo.png" alt="Fundo">
      <b-card class="login-card">
          <div>
              <b-form @submit.prevent="login">

                <h2 class="title">Login</h2>

                <h8 class="label">Documento*</h8>
                <b-form-input
                    v-model="user.document"
                    type="text"
                    placeholder="Documento"
                    v-mask="['###.###.###-##']"
                    required
                ></b-form-input>

                <h8 class="label">Senha*</h8>
                <b-form-input
                    v-model="user.password"
                    type="password"
                    placeholder="Senha"
                    required
                ></b-form-input>

                <b-button pill variant="outline-primary" type="submit">Entrar</b-button>

              </b-form>
          </div>
      </b-card>
  </div>
</div>
</template>

<style lang="stylus" scoped>

    @import '../style/colors.styl';
    @import '../style/fonts.styl';

    .logo-nav
      padding-left: 85px

    nav
      position: relative
      z-index: 1
      background-color: secondary
      box-shadow: 0 1px 15px gray
      height: 100px
      display: flex
      align-items: center

    .interface
        min-height 100%
        display flex
        align-items center
        background-color secondary

    .image
        width: 100%
        height: 100%
        object-fit: cover
        position: absolute
        top: 0
        left: 0

    .login-card
        position: absolute;
        top: 55%
        left: 70%
        transform: translate(-50%, -50%)
        width 382px
        height: 479px
        margin 0 auto
        display flex
        background-color: secondary
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 8px

    .card-body, .card-body > div
        display flex
        justify-content space-around
        align-items center
        flex-basis 100%
        flex-grow 0
        flex-wrap wrap
        align-content center

    .label
        align-self: left

    form
        width 100%

        *
            font-family Rubik-Medium

        .title
            text-align center
            color primary
            margin-bottom: 20px

        input, button
            height calc(1.5em + .75rem + 2px)
            padding .4rem 1rem

        input
            margin-bottom: 25px
            border-radius: 50rem

        button
            width 100%

    // Remove icon
    @media (max-width: 680px)

      .login-card
        position: absolute;
        top: 55%
        left: 50%
        transform: translate(-50%, -50%)
        width 382px
        height: 479px
        margin 0 auto
        display flex
        background-color: secondary
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 8px

</style>

<script>

import { request } from '@/services'
import { toast } from '@/utils'

export default {
  data: () => ({
    user: {
      group_key: 'Super Admin',
      document: '',
      password: ''
    }
    // show: false, // password show/hide eye
  }),
  methods: {
    login () {
      request.post('login', this.user, response => {
        this.$store.dispatch('login', response.data)

        request.get('role/index', response => {
          this.$store.dispatch('rules/init', response.data.roles)
          this.$router.replace({ path: '/' })
        }, error => {
          toast(this, 'danger', 'Erro ao obter regras', error)
        })
      }, error => {
        toast(this, 'danger', 'Login inválido', error)
      })
    }
  }
}
</script>
