import Vue from 'vue'
import Vuex from 'vuex'

import { api } from '@/services'

// Modules
import Samples from './modules/samples'
import SampleImgs from './modules/images'
import Classificar from './modules/classificar'

// Modules Adm
import Groups from './modules/groups'
import Rules from './modules/rules'
import Users from './modules/users'
import AtivesUser from './modules/ativesUser'
import Atives from './modules/atives'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: JSON.parse(localStorage.user || '{}'),
    token: localStorage.token || undefined,
    dashboard: ['groups', 'rules', 'users', 'ativesUser', 'atives', 'samples', 'images', 'classificar'] // active module
  },
  getters: {
    userName: state => {
      return state.user.name === undefined ? '' : state.user.name
    },
    isLogged: state => Object.keys(state.user).length !== 0 && state.token !== undefined
  },
  mutations: {
    login: (state, payload) => {
      state.user = payload.user
      state.token = payload.token
    },
    logout: state => {
      state.user = {}
      state.token = undefined
    }
  },
  actions: {
    login: (context, payload) => {
      localStorage.user = JSON.stringify(payload.user)
      localStorage.token = payload.token
      api.defaults.headers.common.Authorization = `Bearer ${payload.token}`
      context.commit('login', payload)
    },
    logout: context => {
      localStorage.clear()
      delete api.defaults.headers.common.Authorization
      context.commit('logout')
    }
  },
  modules: {
    groups: Groups,
    samples: Samples,
    images: SampleImgs,
    classificar: Classificar,
    rules: Rules,
    users: Users,
    ativesUser: AtivesUser,
    atives: Atives
  }
})
