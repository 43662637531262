<script>

/**
 * This Mixin should only be imported in Dashboard main component
 */

// Register new modals from component/modals
const modals = ['Form', 'Delete', 'Save']

export default {
  data: () => {
    return {
      activeModal: null,
      payload: {}
    }
  },

  // Import all components dynamically
  components: modals.reduce((obj, name) =>
    Object.assign(obj, { [name]: () => import('@/components/modals/' + name) }), {}),

  methods: {
    callModal (name, props) {
      // refresh props
      this.payload = props

      // if modal already loaded just call it again
      // otherwise modal will be rendered and called on mounted
      if (this.activeModal === name) {
        this.$bvModal.show(name + '-modal')
        return
      }

      // Change modal
      this.activeModal = name
    }
  }
}

</script>
