<template>
    <div>
        <Title :title="title" :form="true" :propActions="submitAction" @submit="submit"></Title>
        <Interface noHeader>
            <Form ref="form" v-bind="$props" @finished="finished" @invalid="invalid"></Form>
        </Interface>
    </div>
</template>

<script>

import Title from '@/components/holders/Title'
import Interface from '@/components/holders/Interface'
import Form from '@/components/builders/Form'

import View from '@/mixins/core/ViewBehavior'

import { toast } from '@/utils'

export default {
  mixins: [View],
  data: () => ({
    submitAction: [{
      key: 'submit',
      event: 'emit',
      options: { label: 'Salvar' }
    }]
  }),
  components: { Title, Interface, Form },
  methods: {
    submit () {
      this.$refs.form.submit()
    },
    finished (wasSuccessfully, logs) {
      if (wasSuccessfully) {
        this.$router.back()
      }
      for (const log of logs) {
        toast(wasSuccessfully ? this.$parent : this, ({ success: 'success', failed: 'danger' })[log.status], log.title, log.msg)
      }
    },
    invalid (msg) {
      toast(this, 'primary', 'Formulário inválido', msg)
    }
  },
  props: ['itemId', 'formKey', 'requests', 'module']
}
</script>
