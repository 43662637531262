<template>
    <div class="title">
        <h2>{{ title }}</h2>

        <!-- Button Events -->
        <template v-if="btnAction">
        <div v-for="(action, index) in btnAction" :key="index" @click="event(action)" class="title-btn">
            <i class="icon-btn material-icons">{{ action.options.icon }}</i>
            <span>{{ action.options.label }}</span>
        </div>
        </template>

        <!-- Back -->
        <template v-if="form">
            <div @click="$router.go(-1)" class="title-btn">
                <i class="icon-btn material-icons">arrow_back</i>
                <span>Voltar</span>
            </div>
        </template>

        <!-- Menu -->
        <template v-if="classificar">
            <div @click="$router.push('/')" class="title-btn">
                <i class="icon-btn material-icons">arrow_back</i>
                <span>Menu</span>
            </div>
        </template>

    </div>
</template>

<style lang="stylus" scoped>

    @import '../../style/colors.styl';
    @import '../../style/fonts.styl';
    @import '../../style/mixins.styl';

    .title-btn-back
      background-color red

    .title
        margin-top: 16px
        margin-bottom 16px

        > h2
            font-family Rubik-Medium

        > span
            font-family Manrope-Bold

        h2, div
            display inline-block
            vertical-align middle

        .title-btn
            margin-left 10px
            float right
            background-color warning
            color white
            border-radius 8px
            padding 6px 12px
            cursor pointer
            transition 0.2s

            &:hover
                background-color alpha(primary, 0.8)

            span, .icon-btn
                vertical-align middle
                no-select()

</style>

<script>

/**
 * Component Holder - Title
 *
 * Display page title and has a button in right
 *
 * Events:
 *   - Button in right (no suffix needed)
 *       + Options:
 *           - label(String)
 *           - icon(String)
 *
 * Props:
 *   - Page Title (title)
 */

import Action from '@/mixins/core/ActionBehavior'
import Event from '@/mixins/core/EventBehavior'

export default {
  mixins: [Action, Event],
  data: () => ({ actionsScope: 'Title' }),
  props: {
    title: { type: String, default: 'No Title' },
    form: { type: Boolean, default: false },
    classificar: { type: Boolean, default: false }
  },
  computed: {
    btnAction () {
      return this.actions.slice(0, 2)
    }
  }
}
</script>
