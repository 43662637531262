<script>

/**
 * This mixin depends of ActionBehavior.vue
 */

import { unwrap } from '@/utils'

export default {
  methods: {
    event (action, data = {}) {
      ({
        modal: this.modal,
        link: this.link,
        redirect: this.redirect,
        emit: this.emit
        // ...
      })[action.event](action, data)
    },

    eventByKey (key, data) {
      this.actions.forEach(action => {
        if (action.key === key) {
          return this.event(action, data)
        }
      })
    },

    // Route to a view
    link (action, data) {
      // Next children route
      const route = { name: this.$route.name + '-' + action.key }

      // Add params if exist
      if ((action.params || []).length > 0) {
        const params = {}
        for (const key of action.params) {
          params[key] = data[key]
        }
        route.params = params
      }

      // Push
      this.$router.push(route)
    },

    // Redirect to any view with route params
    redirect (action, data) {
      this.$router.push({ name: action.key, params: unwrap(action.params, data) })
    },

    // Call Modal
    modal (action, data) {
      // Find dashboard main instance
      // it's a weird solution, but is the lesser worst
      let i = this
      while (i) {
        if (i.$options.name === 'Dashboard') break
        i = i.$parent
      }

      // This is not recommended by vue but is much more easier than use $listener
      // broadcast emit to parents sounds very discourage too
      i.callModal(action.component, unwrap(action.props, data))
    },

    // Emit event vue
    emit (action, data) {
      this.$emit(action.key, data)
    }
  }
}
</script>
