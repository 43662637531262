<template>
  <div>
    <div @click="myPost" class="title-btn">
      <i class="icon-btn material-icons">save</i>
      <span>Salvar</span>
    </div>
    <Title v-if="!noTitle" :title="title" form="true"></Title>
    <Interface
        :lineLabel="name[0]" :sortOptions="sortOptions"
        @sort="sort" @filter="filterSwitch"
    >
      <!-- Table -->
      <b-table sticky-header="100vh" style="padding-bottom: 16px;"
          id="my-table"
          :items="outputs_measures"
          :fields="fields"
          :busy.sync="isBusy"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :filter="filterData"
          :filter-function="filter"
          @filtered="onFiltered"
      >
        <!-- Edit -->

        <template
          v-slot:cell()="{ value, item, field: { key } }"
        >
          <template v-if="edit !== item.func - 1">{{ value }}</template>
          <template v-else>
            <b-form-input v-if="key !== 'variable' && key != 'func'" v-model="item[key]" />
            <b-form-select v-else-if="key != 'func'" v-model="selected[item.func - 1]" :options="variables" value-field="key" text-field="key"></b-form-select>
            <template v-else>{{ value }}</template>
          </template>
        </template>
        <template v-slot:cell(edit)="{ item }">
          <div class="btn-icons btn-actions">
            <i
              v-if="edit !== item.func - 1"
              @click="onEdit(item.func - 1)"
              class="material-icons btn-icon btn-icon-edit"
              >edit
            </i>
            <i v-else @click="onCheck(item.func - 1)" class="material-icons btn-icon"
              >check</i>
          </div>
        </template>

        <!-- Custom th for filter inputs -->
        <template #head()="key">

            <!-- Inputs -->
            <template>
                <div>{{ key.label }}</div>
                <input v-show="isFiltering"
                    type="text" class="form-control" :id="'filter-' + key.field.key"
                    v-model="filterData[key.field.key]"
                />
            </template>
        </template>

        <!-- Filter Search Empty -->
        <template #emptyfiltered="scope">
            <p class="my-2 pl-2">{{ scope.emptyFilteredText }}</p>
        </template>
      </b-table>

      <!-- Pagination -->
      <b-pagination
        v-model="currentPage[0]"
        :total-rows="totalRows[0]"
        :per-page="perPage[0]"
        size="md"
        align="right"
        class="my-0"
      ></b-pagination>
      <br>
    </Interface>
  </div>
</template>

<script>

import Title from '@/components/holders/Title'
import Interface from '@/components/holders/Interface'
import Action from '@/mixins/core/ActionBehavior'
import Event from '@/mixins/core/EventBehavior'
import { api } from '@/services'
import { toOptions, toast } from '@/utils'
// import Loading from '@/components/static/Loading'

export default {
  components: { Title, Interface },
  mixins: [Action, Event],
  data: () => ({
    currentPage: 1,
    perPage: 8,
    sortBy: '',
    isFiltering: false,
    filterData: {},
    totalRows: 1,
    name: ['Saídas', 'Variáveis de controle'],
    outputs_settings: [],
    measures: [],
    variables: [],
    selected: [],
    preconfig: {},
    isBusy: false,
    edit: null,
    outputs_measures: [],
    fields: [
      {
        key: 'func',
        label: 'Saída'
      },
      {
        key: 'variable',
        label: 'Variável'
      },
      {
        key: 'setpoint',
        label: 'Min'
      },
      {
        key: 'hysteresis',
        label: 'Max'
      },
      {
        key: 'edit',
        label: ''
      }
    ]
  }),
  props: {
    noTitle: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    systemId: {
      type: String,
      default: ''
    }
  },
  mounted () {
    this.myProvider()
  },
  computed: {
    sortOptions () {
      return toOptions(this.fields, { value: 'key', text: 'label' }) // Manual fields
    }
  },
  methods: {
    async myProvider () {
      var i = 0
      var a = 0
      var b = 0

      this.outputs_measures = []
      this.isBusy = true
      api.get('system/show/' + this.systemId).then(response => {
        this.outputs_settings = response.data.system.outputs_settings
        this.measures = response.data.system.measures
        this.variables = response.data.system.variables
        this.totalRows = this.outputs_settings.length

        for (i = 0; i < this.outputs_settings.length; i++) {
          a = i * 2
          b = i * 2 + 1

          this.outputs_measures.push({
            func: i + 1,
            variable: this.outputs_settings[i].variable.toUpperCase(),
            setpoint: this.measures[a].value,
            hysteresis: this.measures[b].value
          })

          this.selected[i] = this.outputs_settings[i].variable.toUpperCase()
        }
      }).catch(error => {
        console.log(error)
      })

      this.isBusy = false
    },
    async myPost () {
      this.isBusy = true
      var outputs = []
      var i = 0
      var a = 0
      var b = 0

      for (i = 0; i < this.outputs_measures.length; i++) {
        a = i * 2
        b = i * 2 + 1
        outputs.push({
          key: this.measures[a].key.toString().toUpperCase(),
          variable: '0',
          setpoint: this.outputs_measures[i].setpoint.toString().toUpperCase(),
          hysteresis: '0'
        })
        outputs.push({
          key: this.measures[b].key.toString().toUpperCase(),
          variable: '0',
          setpoint: this.outputs_measures[i].hysteresis.toString().toUpperCase(),
          hysteresis: '0'
        })
      }

      // adicionando as saidas
      for (i = 0; i < this.outputs_measures.length; i++) {
        a = i * 2
        b = i * 2 + 1
        outputs.push({
          key: 'FUNC_SD' + (i + 1).toString(),
          variable: this.outputs_measures[i].variable.toString(),
          setpoint: 'SP_MIN_SD' + (i + 1).toString(),
          hysteresis: 'SP_MAX_SD' + (i + 1).toString()
        })
      }

      // definindo parametros
      this.preconfig = {
        system_id: this.systemId,
        outputs_settings: outputs
      }

      api.post('system/assets/preconfig', this.preconfig).then((response) => {
        console.log(response)

        if (response.status === 200) {
          toast(
            this,
            'success',
            'Configurações atualizadas',
            'Configurações atualizadas com sucesso'
          )
        }
      },
      error => {
        toast(
          this,
          'danger',
          'Configurações não atualizadas',
          error.message +
              (error.response.status === 422
                ? ' => ' + error.response.data.errors
                : '')
        )
        return Promise.reject(error)
      })

      this.$router.go(-1)
      this.isBusy = false
    },
    onEdit (index) {
      console.log('edit: ' + index)
      this.selected[index] = this.outputs_measures[index].variable
      this.edit = this.edit !== index ? index : null
      console.log('this.selected: ' + this.selected[index])
    },
    onCheck (index) {
      console.log(index)
      console.log(this.outputs_measures)
      this.outputs_measures[index].variable = this.selected[index]
      this.edit = this.edit !== index ? index : null

      console.log('this.selected final: ' + this.selected[index])
    },
    sort (key) {
      this.sortBy = key
    },
    filterSwitch (value) {
      this.isFiltering = value
      if (!value) this.filterData = {} // clear inputs when filter close
    },
    filter (row, filters) {
      if (
        !this.isFiltering ||
        // Search for any "true value" on filters
        !Object.keys(filters).reduce((result, value) => result || !!filters[value], false)
      ) { return true }

      // One match verification by includes
      for (const key in filters) {
        if (row[key]?.toString().includes(filters[key]) && !!filters[key]) {
          return true
        }
      }

      return false
    },
    onFiltered (filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  }
}
</script>

<style lang="stylus">

    @import '../../style/colors.styl';
    @import '../../style/mixins.styl';

    .title-btn
            margin-left 10px
            float right
            background-color primary
            color white
            border-radius 8px
            padding 6px 12px
            cursor pointer
            transition 0.2s
            // margin-top -10px

            &:hover
                background-color alpha(primary, 0.8)

            span, .icon-btn
                vertical-align middle
                no-select()

    .subtitle-table
      display block
      align-self center
      padding-left 15px
      position relative
      font-size smaller
      font-style normal

    i.material-icons.btn-icon.btn-icon-edit
      opacity 0
      transition 0.3s
      background-color #ffc700

    .table
        color color-theme(true)
        border-collapse separate
        border-spacing 0 1em

    .table.b-table > thead > tr > .table-b-table-default, .table.b-table > tbody > tr > .table-b-table-default, .table.b-table > tfoot > tr > .table-b-table-default
        background-color content-theme()
        color unset

    .table td, .table th, .table thead th
        border none

    .table tr
        background-color content-base-theme()

    radius = 10px

    .table tr td:first-child
        border-top-left-radius radius
        border-bottom-left-radius radius

    .table tr td:last-child
        border-top-right-radius radius
        border-bottom-right-radius radius

    .table th div
        nowrap()

    .table.b-table > tbody > .table-active, .table.b-table > tbody > .table-active > th, .table.b-table > tbody > .table-active > td
        background-color content-base-theme()

    .table tr:focus
        outline none

    .table.b-table tr
      .btn-actions
          min-width 64px
      i
          opacity 0
          transition .3s

      &:hover
          i
              opacity 1

    .btn-icon-edit
      background-color: #ffc700

    .checkbox
        display block
        width 25px
        height 25px
        background-color #414D55
        padding 4px
        border-radius 4px
        cursor pointer

        .checkbox-selected
            min-height 100%
            border-radius: 4px
            transition 0.2s
            transform scale(0.2)

        .active
            background-color primary
            width auto
            height auto
            transform scale(1)

</style>
