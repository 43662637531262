<script>

export default {
  computed: {
    actions () {
      if (this.propActions) return this.propActions

      const keys = this.$route.name.split('-')

      // first key (keys[0]) is always module
      // not use module from props, module of prop is not necessarily the same of router
      let actions = this.$store.state[keys[0]].actions.children

      // i = 1 to jump module (main action)
      for (let i = 1; i < keys.length; i++) {
        // No actions
        if (!actions || actions.length === 0) {
          throw new TypeError('Actions children null or empty. [' + keys[0] + ']')
        }

        const action = actions.find(a => a.key === keys[i])

        // Action not found on children
        if (!action) {
          throw new TypeError('Route name mismatch with actions of [' + keys[0] + ']')
        }

        actions = action.children
      }
      return (actions || []).filter(a => (a.scope || 'View') === this.actionsScope)
    }
  },
  props: {
    // If passed this will overwritten actions from module
    propActions: { type: Array, required: false }
  }
}

</script>
