<template>
    <Images v-bind="$props" v-slot="props">
        <!-- Body Text -->
        <b-card-text>
            <Buttons class="event-btn" :data="props.item"></Buttons>
            <div class="image-container">
              <img :src="require(`@/assets/samples/${props.values.image}`)" alt="Imagem" height="200">
            </div>
            <br /><br />
            <h5>{{ props.item.key }}</h5>
        </b-card-text>
    </Images>
</template>

<style lang="stylus" scoped>

@import '../../../style/colors.styl';
@import '../../../style/mixins.styl';

.header
    color #4472C4
    border-bottom 1px dashed #25292d
    padding-bottom 15px
    margin-bottom 15px
    min-height 60px
    vertical-align middle

.header p
    text-overflow: ellipsis

.btn-show
    float right
    margin-top -15px
    border-radius 32px
    background-color content-theme()
    padding 8px 16px
    color #ffffff
    cursor pointer

.event-btn
    background-color: warning

.image-container
    width: 100%
    height: 100%
    overflow: hidden

.image-container img
    max-width: 100%
    height: auto

</style>

<script>

import Images from '@/components/builders/Image'

import Buttons from '@/components/holders/Buttons'

import View from '@/mixins/core/ViewBehavior'
import Action from '@/mixins/core/ActionBehavior'

import { dateFormat } from '@/utils'

export default {
  mixins: [View, Action],
  components: { Images, Buttons },
  props: ['modelKey'],
  methods: {
    dateFormat
  }
}
</script>
