<script>

export default {
  data: () => ({ actionsScope: 'View' }),
  computed: {
    state () { return this.$store.state[this.module] },
    name () { return this.state.name },
    items () { return this.state.items }
  },
  props: {
    module: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: false
    }
  }
}

</script>
