import { baseMutations, baseActions } from '../functions'

export default {
  namespaced: true,
  state: {
    // Info
    items: [],
    name: 'Regras',
    loading: true,
    noMenu: true,
    noView: true,

    // API Get index
    get: {
      urn: 'role/index',
      response: 'roles'
    },

    actions: {},

    // API routes
    requests: {},

    // Models
    models: {
      fields: [
        { key: 'key', label: 'Chave' },
        { key: 'description', label: 'Descrição' }
      ]
    },

    // Forms
    forms: {}

  },
  mutations: Object.assign({}, baseMutations, {
    // ...
  }),
  actions: Object.assign({}, baseActions, {
    // ...
  })
}
