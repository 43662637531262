<template>
    <div class="d-flex justify-content-between align-items-center" style="background-color: #F5F5F5;">
        <template v-if="actions.length > 0">
            <div v-for="action in actions"
                :class="'btn-action-' + action.options.theme"
                :key="action.key"
                @click="event(action, data)">
                <i v-if="action.options.theme == 'warning'" class="material-icons button-icon btn-icon">list</i>
                <i v-if="action.options.theme == 'danger'" class="material-icons button-icon btn-icon">delete</i>
                <p class="assets" v-if="action.options.assets">Assets</p>
                <div class="subtitle-btn">
                  <p v-if="action.options.theme == 'warning'">Editar</p>
                  <p v-else-if="action.options.theme == 'danger'">Remover</p>
                </div>
            </div>
        </template>
    </div>
</template>

<script>

import Action from '@/mixins/core/ActionBehavior'
import Event from '@/mixins/core/EventBehavior'

export default {
  mixins: [Action, Event],
  data: () => ({ actionsScope: 'Buttons' }),
  props: {
    data: { type: Object, required: false }
  }
}
</script>

<style lang="stylus" scoped>

    @import '../../style/colors.styl';

    .subtitle-btn
      width 90px
      margin-top 10px
      display flex
      position relative
      font-size: 13px
      color: black

    .assets
      display block
      position relative
      font-size: 13px
      margin-top 40px
      color: #9ea7ac

    .button-icon
      background-color transparent
      margin 3px
      padding-bottom 0px
      margin-bottom 0px

    btn-action()
        border-radius 50%
        width 40px
        height 40px
        cursor pointer
        transition .25s
        margin 15px
        display flex
        justify-content center
        flex-wrap wrap
        flex-direction row

    colors = { success: primary, warning: warning, danger: danger }

    for name, v in colors
        .btn-action-{name}
            btn-action()
            background-color v

            &:hover
                background-color darken(v, 20%)

</style>
