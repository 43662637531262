import { baseMutations, baseActions } from '../functions'

export default {
  namespaced: true,
  state: {

    // Info
    items: [],
    name: 'Análise',
    loading: true,
    noMenu: true,

    menu: {
      title: 'Imagens'
    },

    // // Routes - Components actions
    actions: {
      // Main Action
      component: 'Cards.Image',
      params: ['classe', 'corte', 'remessa', 'arranjo', 'origem', 'localidade', 'bucket'],
      props: route => ({
        classe: route.params.classe,
        corte: route.params.corte,
        remessa: route.params.remessa,
        arranjo: route.params.arranjo,
        origem: route.params.origem,
        localidade: route.params.localidade,
        bucket: route.params.bucket,
        title: 'Visualizar Análise',
        module: 'images',
        modelKey: 'card'
      }),
      children: [
        {
          key: 'add',
          scope: 'Title',
          options: { label: 'Salvar Análise' },
          event: 'modal',
          component: 'Save',
          request: 'add',
          props: item => ({ item: item, module: 'images' })
        },
        {
          key: 'delete',
          scope: 'Title',
          options: { label: 'Deletar' },
          event: 'modal',
          component: 'Delete',
          request: 'delete',
          props: item => ({ item: item, module: 'images' })
        }
      ]
    },

    // API routes
    requests: {
      add: {
        title: 'Salvar Análise',
        success: 'Análise salva',
        urn: '/object/store',
        method: 'post',
        dispatch: 'add'
      },
      delete: {
        title: 'Deletar Análise',
        success: 'Análise deletada com successo',
        urn: '/analisar/deletar?classe=:classe&corte=:corte&remessa=:remessa&arranjo=:arranjo&origem=:origem&localidade=:localidade',
        method: 'deleteIot',
        dispatch: 'delete'
      }
    },

    // Models
    models: {
      card: [
        { key: 'image', slot: 'image' }
      ]
    },

    // Forms
    forms: {
    }

  },

  mutations: Object.assign({}, baseMutations, {
    SET_ITEMS (state, payload) {
      const {
        newItems,
        classe,
        corte,
        remessa,
        arranjo,
        origem,
        localidade,
        bucket,
        date
      } = payload
      state.items = newItems.map(item => ({
        key: item,
        classe: classe,
        corte: corte,
        remessa: remessa,
        arranjo: arranjo,
        origem: origem,
        localidade: localidade,
        bucket: bucket,
        slot: `${date}/${classe}.${corte}.${remessa}.${arranjo}.${origem}.${localidade}/` + item
      }))
      state.loading = false
    }
  }),
  actions: Object.assign({}, baseActions, {
    items () {
      return this.$store.state.items
    }
  })
}
