<template>
    <Card v-bind="$props" v-slot="props" :row="3">
        <b-card-title class="header">
            <div class="d-flex justify-content-between align-items-start">
                <div class="d-flex flex-column">
                    <span class="title">{{ props.values.title }}</span>
                    <span class="subtitle">{{ props.values.category.key }}</span>
                    <span class="sui">{{ props.values.sui }}</span>
                </div>

                <!-- Events -->
                <Buttons :data="props.item"></Buttons>

            </div>
        </b-card-title>

        <div class="btn btn-success mx-auto py-2 d-block" @click="event(btnAction, props.item)">Ativos</div>

    </Card>
</template>

<style lang="stylus" scoped>

@import '../../../style/colors.styl';
@import '../../../style/fonts.styl';

.btn
    width 32%
    background-color primary

span
    font-family Manrope

.title
    font-family Manrope-Bold
    font-size: 0.8em
    margin-bottom 2px

.subtitle
    font-size: 0.55em
    margin-bottom 8px
    color #66667A

.sui
    font-size: 0.5em
    color #4472C4

</style>

<script>

import Card from '@/components/builders/Card'

import Buttons from '@/components/holders/Buttons'

import View from '@/mixins/core/ViewBehavior'
import Action from '@/mixins/core/ActionBehavior'
import Event from '@/mixins/core/EventBehavior'

import { dateFormat } from '@/utils'

export default {
  mixins: [View, Action, Event],
  components: { Card, Buttons },
  props: ['modelKey'],
  methods: {
    dateFormat
  },
  computed: {
    btnAction () {
      return this.actions[0]
    }
  }
}
</script>
