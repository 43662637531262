<template>
    <Classificar v-bind="$props" v-slot="props">
        <!-- Body Text -->
        <b-card-text>
          <b-row>

            <!-- Image -->
            <b-col md="7" cols="12">
              <div class="image-container" :style="{ visibility: urlImage ? 'hidden' : 'visible', position: 'absolute' }">
                <img v-if="props.item.url" ref="image" :src="props.item.url" @load="drawRectangles()" alt="Imagem">
                <img v-else ref="image" :src="require(`@/assets/samples/${props.item.image[0]}`)" @load="drawRectangles()" alt="Imagem">
              </div>
              <div v-if="urlImage" class="image-container">
                <img :src="urlImage" alt="Imagem">
              </div>
            </b-col>

            <!-- Form Space -->
            <b-col md="5" cols="12" style="margin-top: 20px;">
              <div style="text-align: center;">
                <h5 style="font-weight: bold;">{{ getSample(' - ').toUpperCase() }}</h5>
              </div>

              <!-- Multiselect -->
              <div v-for="field in form" :key="field.name" style="margin: 20px;">
                <label class="typo__label">{{ field.name }}</label>
                <multiselect
                v-model="field.value"
                tag-placeholder="Adicionar Opção"
                placeholder="-- Selecione uma Opção --"
                label="description"
                track-by="key"
                :options="field.options"
                :multiple="true"
                >
                </multiselect>
              </div>

              <!-- Zoom -->
              <div v-if="form.zoom.value.length > 0" class="zoom-container">
                ZOOM IMAGES
                <div v-for="value in form.zoom.value" class="zoom-images" :key="value.key">
                  GRÃO: {{ value.description }}
                  <br />
                  <div class="image-zoom-box">
                    <div v-if="props.item.url">
                      <img v-for="url in props.item.url" :key="url" :src="url"  style="margin-right: 20px;" :style="zoomImage(value.description)" alt="Imagem">
                    </div>
                    <div v-else>
                      <img v-for="image in props.item.image" :key="image" :src="require(`@/assets/samples/${image}`)"  style="margin-right: 20px;" :style="zoomImage(value.description)" alt="Imagem">
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
            <!-- End Form -->

          </b-row>
        </b-card-text>
    </Classificar>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="stylus" scoped>

@import '../../../style/colors.styl';
@import '../../../style/mixins.styl';

.header
    color #4472C4
    border-bottom 1px dashed #25292d
    padding-bottom 15px
    margin-bottom 15px
    min-height 60px
    vertical-align middle

.header p
    text-overflow: ellipsis

.btn-show
    float right
    margin-top -15px
    border-radius 32px
    background-color content-theme()
    padding 8px 16px
    color #ffffff
    cursor pointer

.event-btn
    background-color: warning

.image-container
    width: 100%
    height: 100%
    overflow: hidden

.image-container img
    max-width: 100%
    height: auto

.zoom-container
    background-color white
    box-shadow: 0 1px 1px gray
    padding 16px 20px
    border-radius 8px

.zoom-images
    border 1px solid gray
    border-radius 8px
    padding 16px 20px
    margin 10px 0px 10px 0px

.image-zoom-box
    margin-top: 10px

.image-zoom-box img
    max-width: 100%
    height: auto

</style>

<script>

import Classificar from '@/components/builders/Classificar'

import View from '@/mixins/core/ViewBehavior'
import Action from '@/mixins/core/ActionBehavior'

import Multiselect from 'vue-multiselect'

import { dateIot } from '@/utils'

export default {
  mixins: [View, Action],
  components: { Classificar, Multiselect },
  props: ['modelKey'],
  data: () => ({
    jsonData: null,
    form: {
      predict: {
        name: 'Predict',
        value: [],
        options: []
      },
      target: {
        name: 'Target',
        value: [],
        options: []
      },
      zoom: {
        name: 'Object Zoom',
        value: [],
        options: []
      }
    },
    map: {
      grao: {
        bbox: [],
        predict: {},
        origin_path: '',
        target: null,
        class: null
      }
    },
    urlImage: '',
    canvas: null
  }),
  mounted () {
    this.loadJsonData()
    this.loadOptionsMultiselect(this.jsonData)
    this.loadMap(this.jsonData)
    this.createCanvas()
  },
  watch: {
    form: {
      handler () {
        // Chama a função para redesenhar retângulos
        this.drawRectangles()
      },
      deep: true
    }
  },
  methods: {
    dateIot,
    loadJsonData () {
      // Load the data from JSON into 'jsonData'
      const jsonFile = this.getSample('.')
      const date = this.dateIot()

      const jsonData = require(`@/assets/samples/${date}/${jsonFile}/${jsonFile + '.json'}`)
      this.jsonData = jsonData
    },
    loadOptionsMultiselect (jsonData) {
      // Predicts
      const predicts = Object.values(jsonData)[0].predict
      const predictOptions = Object.keys(predicts).map(predict => ({
        description: predict.charAt(0).toUpperCase() + predict.slice(1),
        key: predict
      }))

      // Target & Predict
      this.form.predict.options = predictOptions
      this.form.target.options = predictOptions

      // Zoom
      Object.keys(jsonData).forEach(key => {
        this.form.zoom.options.push({
          description: key,
          key: key
        })
      })
    },
    loadMap (jsonData) {
      // Load the JSON structure into 'map'
      this.map = {}

      Object.entries(jsonData).forEach(([grao, data]) => {
        // Sorts the probabilities of the predictions in descending order
        const predict = data.predict
        const classes = Object.keys(predict)
        const sortedClasses = classes.sort((a, b) => {
          const scoreA = String(predict[a])
          const scoreB = String(predict[b])
          return scoreB.localeCompare(scoreA)
        })

        this.$set(this.map, grao, {
          bbox: data.bbox,
          predict: data.predict,
          origin_path: data.origin_path,
          target: data.target,
          class: sortedClasses[0]
        })
      })
    },
    getSample (join) {
      // getSample structures the 'sample' information according to the received parameter 'join'
      const params = { ...this.$route.params }
      delete params.object_id

      const keys = Object.keys(params)
      const values = keys.map(key => params[key])
      return values.join(join)
    },
    createCanvas () {
      this.canvas = document.createElement('canvas')
      this.$refs.image.parentNode.appendChild(this.canvas)
    },
    drawRectangles () {
      if (!this.canvas) return

      // dimensões originais da imagem
      const originalWidth = this.$refs.image.naturalWidth
      const originalHeight = this.$refs.image.naturalHeight

      // dimensões da imagem na div
      const containerWidth = this.$refs.image.offsetWidth
      const containerHeight = this.$refs.image.offsetHeight

      // Limpa o canvas
      const ctx = this.canvas.getContext('2d')
      ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)

      // Define o tamanho do canvas
      this.canvas.width = containerWidth
      this.canvas.height = containerHeight

      // Desenha a imagem no canvas
      ctx.drawImage(this.$refs.image, 0, 0, containerWidth, containerHeight)

      const graos = Object.values(this.map)
      const selectedPredicts = Object.values(this.form.predict.value).map(value => value.key)
      const selectedTarget = Object.values(this.form.target.value).map(value => value.key)

      // Filtrar os "grãos" com base nas predicts selecionadas
      const predictGraos = graos.filter(grao => {
        return selectedPredicts.includes(grao.class)
      })

      // Filtrar os "grãos" com base nas classes selecionadas
      const targetGraos = graos.filter(grao => {
        return selectedTarget.includes(grao.target)
      })

      const image = this.$refs.image
      ctx.drawImage(image, 0, 0, containerWidth, containerHeight)

      graos.forEach((grao, index) => {
        const coords = grao.bbox
        const x = coords[0] * (containerWidth / originalWidth)
        const y = coords[1] * (containerHeight / originalHeight)

        // Desenha o número
        ctx.fillStyle = 'white'
        ctx.font = '12px Arial'
        ctx.fillText(index, x + 12, y + 17)
      })

      targetGraos.forEach(grao => {
        const coords = grao.bbox
        const x = coords[0] * (containerWidth / originalWidth)
        const y = coords[1] * (containerHeight / originalHeight)
        const width = (coords[2] - coords[0]) * (containerWidth / originalWidth)
        const height = (coords[3] - coords[1]) * (containerHeight / originalHeight)

        ctx.strokeStyle = 'red'
        ctx.lineWidth = 2
        ctx.strokeRect(x, y, width, height)
      })

      predictGraos.forEach(grao => {
        const coords = grao.bbox
        const x = coords[0] * (containerWidth / originalWidth)
        const y = coords[1] * (containerHeight / originalHeight)
        const width = (coords[2] - coords[0]) * (containerWidth / originalWidth)
        const height = (coords[3] - coords[1]) * (containerHeight / originalHeight)

        ctx.strokeStyle = 'green'
        ctx.lineWidth = 2
        ctx.strokeRect(x, y, width, height)
      })

      // Define a URL da imagem do canvas
      this.urlImage = this.canvas.toDataURL()
    },
    zoomImage (grao) {
      const graos = Object.values(this.map)
      const selectedGrao = graos[grao]

      const coords = [
        selectedGrao.bbox[0],
        selectedGrao.bbox[1],
        selectedGrao.bbox[2],
        selectedGrao.bbox[3]
      ]

      const x = coords[0]
      const y = coords[1]
      const width = (coords[2] - coords[0])
      const height = (coords[3] - coords[1])

      return {
        objectFit: 'none',
        objectPosition: `-${x}px -${y}px`,
        width: `${width}px`,
        height: `${height}px`
      }
    }
  }
}
</script>
